import './App.css';

function App() {
  return (
    <body class="dark-theme">
      <p>This domain did not comply amazon affiliate policy.</p>
      
      <p>So we are taking down this domain.</p>
      
      <p>Thank you all for your love and support!</p>

      <h1>Adios</h1>
    </body>
  );
}

export default App;
